import { ImageBackground, ScrollView, TextInput, TouchableHighlight } from 'react-native';
import { getDocumentAsync } from "expo-document-picker"

//import { DocumentPicker, ImagePicker } from 'expo';
import { Flex, FormLabel, Text, View } from '../../components/Themed';

import React, { useEffect, useRef, useState } from "react";

import { CONSTANTS, styles } from "../../constants/Styles";
import ButtonText from '../../components/ButtonText';
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import { blake2AsHex, mnemonicToMiniSecret } from "@polkadot/util-crypto";
import {
    ConvertEdPublic2CurvePublic,
    ConvertEdSecret2CurvePair,
    encrypt,
    toHexString
} from '../../diamond-realm-api/crypto';
import { box, sign } from "tweetnacl";
import * as ed2curve from 'ed2curve';
import { Keypair } from "@polkadot/util-crypto/types";
import {
    CreateContact,
    GetContact, GetContacts,
} from "../../diamond-realm-api/contacts";
import { useForm } from "../../hooks/useForm";
import { useBlockchain } from "../../diamond-realm-api/hooks/useBlockchain";
import Loading from "../../components/Loading";
import { Contact, ContactHeader, FileHeaderI, FileI, FolderHeaderI } from "../../diamond-realm-api/interfaces";
import useInterval from "../../hooks/useInterval";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import Colors from "../../constants/Colors";
import { faContactBook, faFolder, faContactCard, faHome, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import FileView from "../../components/Storage/FileView";
import { AddressShort } from "../../utils/addressUtils";
import ContactView from "../../components/Contacts/ContactView";
import ButtonUi from "../ButtonUi";
import AccountNickText from "../AccountNickText";
import ContactListItem from "./ContactListItem";

interface props {
    setValue: (adr: string) => void
}

export default function ContactsPicker(
    {
        setValue
    }: props) {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const input_ref = useRef<TextInput | null>(null);
    const [folders, setFolders] = useState<FolderHeaderI[]>([])
    const [contacts, setContacts] = useState<ContactHeader[]>([])
    const [loading, setLoading] = useState(false)

    const [showNewContact, setShowNewContact] = useState(false)

    const initForm = {
        firstName: "",
        lastName: "",
        diamondAddress: "",
        email: "",
        phone: "",
        websiteUrl: "",
        avatarUr: "",
        company: "",
        folderHeader: {
            parentId: "",
            name: "/"
        }
    }
    const form = useForm(initForm)

    useEffect(() => {
        refresh()
    }, [blockchain.api])

    useEffect(() => {
        refresh()
    }, [form.formData.folderHeader])

    useInterval(() => {
        refresh()
    }, 8000)

    // end of hooks

    const refresh = async () => {
        handleGetContacts()
    }


    const handleCreateContact = async () => {
        form.setSubmitting(true)
        console.log("create contact", form.formData)
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            const f = form.formData
            let data: Contact = {
                firstName: f.firstName,
                lastName: f.lastName,
                email: f.email,
                phone: f.phone,
                websiteUrl: f.websiteUrl,
                avatarUrl: f.avatarUrl,
                company: f.company,
                diamondAddress: f.diamondAddress,
            }
            let contactName = data.firstName + " " + data.lastName
            try {
                let res = await CreateContact(
                    blockchain.api,
                    wallet.localWallet,
                    wallet.curvePair,
                    form.formData.folderHeader,
                    contactName,
                    data,
                    () => {
                        // form.setFormData(initForm)
                        form.setSubmitting(false)
                        setShowNewContact(false)
                    }
                )

            } catch (err) {
                console.log(err)
                form.setSubmitting(false)
            }
        }
    }


    const handleGetContacts = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            setLoading(true)
            let r = await GetContacts(
                blockchain.api,
                wallet.curvePair,
                form.formData.folderHeader,
            )
            if (r) {
                setContacts(r)
            }
        }
    }


    // todo folder on contacts
    /*   const handleGetFolders = async () => {
           if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
               let r = await GetFolders(
                   blockchain.api,
                   wallet.curvePair,
                   form.formData.folderHeader,
               )
               if (r) {
                   setFolders(r)
               }
           }
       }
   */

    const handleGetContact = async (name:string) => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetContact(
                blockchain.api,
                wallet.curvePair,
                form.formData.folderHeader,
                // todo
                name,
            )
            return r
        }
    }



    if (loading && !contacts) return <Loading />
    return (
        <View style={styles.card}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    //alignItems: "center",
                    // backgroundColor: Colors.dark.backgroundAlpha,
                    width: "100%",
                    // padding: 12,
                    // paddingBottom: 100,
                    maxWidth: CONSTANTS.pageWidth,
                }}>
                <View style={{
                    // paddingBottom: 100,
                }}>


                    <View style={{
                        //maxWidth: 420,
                    }}>
                        {contacts.map((contact, i) => (
                            <View key={"contact" + i} style={{
                                flexDirection:"row",
                                alignContent:"stretch",
                            }}>
                                
                                <ButtonUi minimal={true} disabled={false}
                                    onPress={() => {
                                        if (form.formData.viewContactName === contact.name) {
                                            let fd = { ...form.formData }
                                            delete fd.viewContactName
                                            form.setFormData(fd)
                                        } else {
                                            form.setFormData({
                                                ...form.formData,
                                                viewContactName: contact.name
                                            })
                                        }
                                    }}>
                                    <ContactListItem folderHeader={form.formData.folderHeader} contactName={contact.name} />
                                </ButtonUi>
                                <ButtonUi minimal={true} disabled={false} onPress={() => {
                                    handleGetContact(contact.name).then(c => {
                                        console.log("contact",c)
                                        if (c?.diamondAddress) {
                                            setValue(c.diamondAddress)
                                        }
                                    })

                                }}    >
                                    <FontAwesomeIcon color={Colors.dark.text} icon={faAddressCard}
                                        style={{ marginRight: 6 }} />
                                </ButtonUi>
                                {(form.formData.viewContactName === contact.name) &&
                                    <View style={{
                                        flex: 1,
                                    }}>
                                        <ContactView folderHeader={form.formData.folderHeader}
                                            contactName={form.formData.viewContactName}
                                            setValue={setValue}
                                        />
                                    </View>
                                }
                            </View>
                        ))}
                    </View>


                </View>

            </ScrollView>
        </View>

    );
}

