import React from "react";
import {toHexString} from "../diamond-realm-api/crypto";
import {AddressShort} from "../utils/addressUtils"
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {View, Flex, Text} from "./Themed";
import {styles} from "../constants/Styles";
import {NavigationProp, NavigatorScreenParams, useNavigation} from "@react-navigation/native";
import {AccountStackParamList, RootStackParamList, RootTabParamList, WalletStackParamList} from "../types";
import {TouchableHighlight} from "react-native";
import {NativeStackNavigationProp, NativeStackScreenProps} from "@react-navigation/native-stack";
import AccountDisplay from "./AccountDisplay";
import {ConnectBlockchain} from "../diamond-realm-api/extension";


const {
    u8aToHex
} = require('@polkadot/util');


type MyComponentNavigationProp = NativeStackNavigationProp<RootTabParamList, "Account">;


const AccountMenu = () => {
    const wallet = useWallet()
    const navigation = useNavigation<MyComponentNavigationProp>()

    const handleClick = async () => {
        if (wallet.address) {
            navigation.navigate('Account', {screen: "Settings"})
        } else {
            ConnectBlockchain()
        }
    }


    return (
        <TouchableHighlight
            underlayColor="rgba(100,120,140,0.12)"
            //disabled={false}
            onPress={() => handleClick()}
        >
            <View
                //cursor={"pointer"}
                //onPress={e => handleClick(e, "account")}
                style={{
                    flex: 1,
                    //alignItems: "flex-end",
                    width: "100%"
                }}
            >
                <View
                    style={{
                        // flex:1,
                        alignItems: "flex-end",
                        // width: "100%"
                    }}
                >
                    {wallet.address ? (
                        <>
                            <Text style={{
                                color: 'rgba(252,254,255,0.92)',
                                fontSize: 22,
                                fontWeight: "200",
                                marginBottom: 2,
                                marginTop: 6,
                                textAlign: "right",
                            }}>
                                {wallet.nick}
                            </Text>
                            <AccountDisplay address={wallet.localWallet?.address}/>
                        </>
                    ) : (
                        <Flex>
                            <Text>
                                
                            </Text>
                        </Flex>
                    )}

                </View>
            </View>
        </TouchableHighlight>
    )
}

export default AccountMenu