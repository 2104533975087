import {ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';
import {mnemonicToMiniSecret, ed25519PairFromSeed} from "@polkadot/util-crypto";
import {Keypair} from "@polkadot/util-crypto/types";
import {Flex, FormLabel, Text, View} from '../../components/Themed';
import React, {useRef, useState} from "react";
import {useForm} from "../../hooks/useForm";
import {styles} from "../../constants/Styles";

import {devSeeds, useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import DisplayKey from "../../components/Keys/DisplayKey";
import ButtonText from "../../components/ButtonText";
import {DiamondKeyring, toHexString} from "../../diamond-realm-api/crypto";

export default function LoadKey() {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const [secretKeyPair, setSecretKeyPair] = useState<Keypair>()

    const initForm = {
        keyInput: ""
    }
    const form = useForm({
        ...initForm,
    })
    const input_ref = useRef<TextInput | null>(null);

    // end of hooks

    const handleEnterSeed = async (e: any) => {
        e.preventDefault()
        console.log(form.formData)
        let phrase = form.formData.keyInput
        const seedFromMnemonic = mnemonicToMiniSecret(phrase);
        const keypair = ed25519PairFromSeed(seedFromMnemonic);
        //const keyringPair = DiamondKeyring.createFromUri(phrase)
        /*  let keypair:Keypair = {
            secretKey: keyringPair.
          }*/
        //console.log( toHexString(keypair.publicKey), keyringPair.address)
        /*
            const seedFromMnemonic = mnemonicToMiniSecret(phrase);
        const keyring = new Keyring({type: 'ed25519'});
        console.log(phrase)
        let key = ed25519PairFromSeed(seedFromMnemonic) // keyring.addFromUri(phrase)
        //const skp = box.keyPair.fromSecretKey(k.)
        setSecretKeyPair(skp)
        console.log("result", seedFromMnemonic, skp)
         */
        // this is  a curve used in data encryption
        // const skp = box.keyPair.fromSecretKey(seedFromMnemonic)

        setSecretKeyPair(keypair)
        console.log("result", seedFromMnemonic, keypair)
    }

    const handleSetLocalKeyFromSeed = async (e: any, seed: string) => {
        e.preventDefault()

        let phrase = seed
        const seedFromMnemonic = mnemonicToMiniSecret(phrase);
        //const keypair = box.keyPair.fromSecretKey(seedFromMnemonic)
        const keypair = ed25519PairFromSeed(seedFromMnemonic);
        setSecretKeyPair(keypair)
        wallet.setLocalWallet({
            secretKey: keypair.secretKey,
            address: keypair.publicKey,
            seedPhrase: phrase,
        })
        form.setFormData({
            ...form.formData,
            keyInput: phrase,
        })
    }

    const handleSetLocalKey = (e: any) => {
        e.preventDefault()
        if (!secretKeyPair) return
        wallet.setLocalWallet({
            secretKey: secretKeyPair.secretKey,
            address: secretKeyPair.publicKey,
            seedPhrase: form.formData.keyInput,
        })        
        //todo somehow do a wallet reload()...
    }


// if (isLoading) return <span>Loading ...</span>;
    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    width: "100%",
                    padding: 12,
                    paddingBottom: 100,
                }}>
                <View //flexDirection={"column"}
                    //flexGrow={1}
                    //padding={"1em"}
                    //marginY={"1em"}
                >

                    <View
                        style={{
                            flex: 1,
                            //flexDirection: "column",
                            marginBottom: 12,
                            marginTop: 12,
                        }}
                    >
                        <View //flexDirection={"column"} flexGrow={1}
                            style={{
                                minWidth: 60,
                            }}
                        >
                            <Text style={[styles.header, styles.titleTop]}>
                                Seed Phrase or Secret Key
                            </Text>
                            <TextInput
                                ref={input_ref}
                                style={styles.input}
                                placeholder="..."
                                autoFocus={false}
                                value={form.formData.keyInput}
                                onChangeText={form.handleChange("keyInput")}
                                //onSubmitEditing={handleSubmit}
                            />
                        </View>


                        <View //flex={1} flexDirection={"column"} marginY={"1em"}
                        >
                            <ButtonText onPress={handleEnterSeed} disabled={!form.formData.keyInput}>
                                Load from Seed
                            </ButtonText>
                        </View>
                        {secretKeyPair && (
                            <DisplayKey localWallet={{
                                address: secretKeyPair.publicKey,
                                secretKey: secretKeyPair.secretKey,
                                seedPhrase: form.formData.keyInput
                            }}/>
                        )}
                    </View>


                    {secretKeyPair && (
                        <View //flexDirection={"column"}
                        >
                            <View>
                                <ButtonText disabled={false} onPress={e => handleSetLocalKey(e)}>
                                    Use this key in your Wallet
                                </ButtonText></View>
                        </View>
                    )}
                    <View>
                        <Text style={[styles.header, styles.titleTop]}>
                            Load Development Test Account
                        </Text>
                        <Flex>
                            {devSeeds.map((seed, i) => (
                                <ButtonText disabled={false} key={"dev-seed::" + i}
                                            onPress={e => handleSetLocalKeyFromSeed(e, seed)}>
                                    {`Dev ${i + 1}`}
                                </ButtonText>
                            ))}
                        </Flex>
                    </View>
                </View>
            </ScrollView>
        </View>


    );
}

