import {DecimalAmount, FormatDecimalAmount, FormatNumber} from "../../utils/NumbersUtils";

import React, {useEffect, useMemo, useState} from "react";
import {useForm} from "../../hooks/useForm";
import Keyring from "@polkadot/keyring";
import Loading from "../Loading";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import Toast from "react-native-toast-message";
import {View, Flex, Text} from "../Themed";
import {styles} from "../../constants/Styles";
import {AssetI} from "../../diamond-realm-api/interfaces";
import {Image, TouchableHighlight} from "react-native";
import {NativeStackNavigationProp, NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList, RootTabParamList, WalletStackParamList} from "../../types";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import {TokenValue} from "../../diamond-realm-api/tokens";
import useTokens from "../../diamond-realm-api/hooks/useTokens";
import {H256} from "@polkadot/types/interfaces/runtime";
import {ImageDimensions} from "../../utils/imageUtils";


interface propsI {
    id: string
}


/*
type NavProps = NativeStackNavigationProp<RootTabParamList, "Wallet">;

const TokenListItem = ({id}: propsI) => {
    const navigation = useNavigation<NavProps>()

*/

const TokenListItem = ({id}: propsI) => {
    const navigation = useNavigation<NavigationProp<WalletStackParamList>>()
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const {accounts, metadata, subscribeToken} = useTokens()
    const [w, setW] = useState(0)
    const [h, setH] = useState(0)

    useEffect(() => {
        // console.log("id", id)
        subscribeToken(id)
    }, [blockchain.api, id, wallet.localWallet])
    //todo meta in useassets

    const account = accounts[id] && accounts[id]
    const m = metadata[id] && metadata[id]

    const [showOptions, setShowOptions] = useState(false)
    const [showTransferForm, setShowTransferForm] = useState(false)
    const [showApr, setShowApr] = useState(false)
    const [showWithdraw, setShowWithdraw] = useState(false)
    const [showDeposit, setShowDeposit] = useState(false)

    useEffect(() => {
        let {w, h} = ImageDimensions(m?.image)
        setW(w)
        setH(h)
    }, [m])

    let tokenValue = useMemo(() => {
        return TokenValue(account?.balance, m);
    }, [account?.balance, m])


    //console.log(account)

    return (
        <TouchableHighlight
            underlayColor="rgba(100,120,140,0.12)"
            //disabled={false}
            /*       onPress={() => navigation.navigate('Wallet', {
                           screen: 'Token',
                           params: {id}
                       }
                   )*/
            onPress={() => navigation.navigate('Token', {id})}
        >
            <View style={{
                flex: 1,
                ...styles.listItem,
            }}>
                <Flex style={{
                    flexWrap: "wrap",
                    alignItems: "center",
                }}>
                    <Flex style={{
                        alignItems: "center",
                        width: "100%",
                        // justifyContent: "space-between",
                        flex: 1,
                    }}>
                        {m?.image &&
                            <Image style={{marginRight: 12}} source={
                                {
                                    width: 36,
                                    height: 36,
                                    uri: m.image
                                }
                            }/>
                        }
                        <View style={{
                            flex: 1,
                        }}>

                            <Flex style={{
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "space-between",
                                flex: 1,
                            }}>
                                <Flex style={{
                                    flex: 1
                                }}>

                                    <Text style={{
                                        marginRight: 6,
                                        fontWeight: "700",
                                    }}>
                                        {m?.name}
                                    </Text>
                                </Flex>
                                <Flex style={{
                                    //flex: 1,
                                    alignItems: "center",
                                    justifyContent: "flex-end",

                                }}>
                                    {/*onClick={e => {
                  e.preventDefault()
                  setShowOptions(!showOptions)
                }}>*/}


                                    <View style={{
                                        //...styles.listItem,
                                       // width: 42,
                                        //paddingLeft: 6,
                                        alignItems: "flex-end"
                                    }}>
                                        {!!tokenValue?.worth &&
                                            <Text style={{
                                                //fontSize: 12,
                                                fontWeight: "700",
                                            }}>
                                                {`$${FormatDecimalAmount(tokenValue.worth, 0, 0)}`}
                                            </Text>
                                        }
                                    </View>
                                </Flex>
                            </Flex>
                            <Flex style={{
                                //...styles.listItem,
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                            }}>
                                {!!tokenValue?.valueUsd &&
                                    <Text style={{
                                        fontSize: 10,
                                        fontWeight: "500",
                                    }}>
                                        {`$${FormatNumber(tokenValue.valueUsd, 2)}`}
                                    </Text>
                                }

                                <Flex>
                                    <Text style={{
                                        fontWeight: "500",
                                        marginRight: 3,
                                        fontSize: 11,
                                    }}>
                                        {m?.decimals && FormatDecimalAmount(parseInt(account?.balance.toString() || "0"), m.decimals)}
                                    </Text>
                                    <Text style={{
                                        fontWeight: "700",
                                        fontSize: 11,
                                    }}>
                                        {m?.symbol}
                                    </Text>
                                </Flex>
                            </Flex>
                        </View>
                    </Flex>
                </Flex>

            </View>
        </TouchableHighlight>
    )
}
export default TokenListItem