import Toast from "react-native-toast-message";
import {ApiPromise, Keyring} from "@polkadot/api";
import {ParseResultEvents, ToastTx} from "../components/Toast";


const {
    stringToU8a,
    u8aToString
} = require('@polkadot/util');



export const FaucetDrip = async (
    api: ApiPromise,
    addr: string,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    console.log(addr, "length", addr.length)
    const keyring = new Keyring({ type: 'sr25519' });

    let kp = keyring.addFromUri('//Alice');
    console.log("kp",kp.address)
    let b = await api.query.balances.account(kp.address)
    console.log("Balance",b.free)


    const unsub = await api.query.system.account(kp.address, ({nonce, data: balance}) => {
        console.log(`ALICE free balance is ${balance.free} with ${balance.reserved} reserved and a nonce of ${nonce}`);
      
    });
unsub()

    try {
        await api.tx.balances.transferAllowDeath(
            addr,
            10000000000000
        ).signAndSend(kp, (result) => {
            console.log(result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return addr
    } catch (err) {
        console.log(err)
        throw err
    }
}



