import {StatusBar} from 'expo-status-bar';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import useCachedResources from './src/hooks/useCachedResources';
import useColorScheme from './src/hooks/useColorScheme';
import Navigation from './src/navigation';
import {styles} from "./src/constants/Styles";
import React from "react";
import {ImageBackground} from "react-native";
import {BlockchainProvider} from "./src/diamond-realm-api/hooks/useBlockchain";
import Toast from "react-native-toast-message";
import ToastConfig from "./src/components/ToastConfig";

//import '@polkadot/api/augment';

//import './src/interfaces/augment-types';
//import './src/interfaces/augment-api';


export default function App() {
  const isLoadingComplete = useCachedResources();
//  const colorScheme = useColorScheme();
  const colorScheme = "dark"
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
        <SafeAreaProvider>
          <BlockchainProvider>
            <Navigation colorScheme={colorScheme}/>
            {/*<Navigation colorScheme={"dark"} />*/}
            <StatusBar style={"dark"} hidden={true}/>
          </BlockchainProvider>
          <Toast config={ToastConfig} />
        </SafeAreaProvider>
    );
  }
}
