import {ApiPromise} from "@polkadot/api";
import {LocalWallet} from "./hooks/useWallet";
import Toast from "react-native-toast-message";
import {asHexAddress, DiamondKeyring, diamondHash, toHexString} from "./crypto";
import BN from "bn.js";
import {decodeAddress} from "@polkadot/keyring";
import {NameServiceNameInfo} from "@polkadot/types/lookup";
import {ParseResultEvents, ToastTx} from "../components/Toast";

// todo integrate or replace with type NameInfo from the chain
export interface NameInfo {
    owner?: string
    name?: string
    id: string
    www?: string
}

export const RegisterName = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    owner: string,
    name: string,
    www:string | null,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    const id = diamondHash(name)

    console.log(name, id, "id length", id.length, "owner",owner,"name",name,"www",www)
    //const seed = mnemonicToMiniSecret(localKey.localWallet.seedPhrase);
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    console.log("kp",kp)
    try {
        await api.tx.nameService.register(
            id,
            owner,
            name,
            www
        ).signAndSend(kp, (result) => {
            console.log("Name Registered", result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
     throw err
    }
}


export const UpdateName = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    name: string,
    www: string | null,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    const id = diamondHash(name)

    console.log(name, id, "id length", id.length)
    //const seed = mnemonicToMiniSecret(localKey.localWallet.seedPhrase);
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    try {
        await api.tx.nameService.update(
            id,
            www,
        ).signAndSend(kp, (result) => {
            console.log("Name Updated", result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
       throw err

    }
}



export const BurnName = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    name: string,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    const id = diamondHash(name)

    console.log(name, id, "id length", id.length)
    //const seed = mnemonicToMiniSecret(localKey.localWallet.seedPhrase);
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    try {
        await api.tx.nameService.burn(
            id,
        ).signAndSend(kp, (result) => {
            console.log("Name Burnt", result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
        throw err
        //callback && callback()
    }
}


export const SetDefaultName = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    //id: string,
    name: string,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    const id = diamondHash(name)

    console.log(name, id, "id length", id.length)
    //const seed = mnemonicToMiniSecret(localKey.localWallet.seedPhrase);
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    try {
        await api.tx.nameService.setDefault(
            id
        ).signAndSend(kp, (result) => {
            console.log("Set Default Name", result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
        console.log("error", err)
        throw err
    }
}


export const TransferName = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    name: string,
    recipient: string,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    const id = diamondHash(name)

    console.log(name, id, "id length", id.length)
    //const seed = mnemonicToMiniSecret(localKey.localWallet.seedPhrase);
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    try {
        await api.tx.nameService.transfer(
            id,
            recipient,
        ).signAndSend(kp, (result) => {
            console.log("Name Transferred", result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
        console.log("error", err)
        Toast.show({
            type: 'tomatoToast',
            text1: `Error`,
            text2: `${err}`,
            visibilityTime: 12000,
        });
        callback && callback()
    }
}


//
// Gets
//

export const GetAllNameIds = async (
    api: ApiPromise,
    // todo
    // some sort of limit and offset
) => {
    try {
        let res = await api.query.nameService.nameOf.entries()
        let a: NameInfo[] = []
        res.forEach(([key, c_]) => {
            let k = key.slice(key.length - 32, key.length)
            a.push({
                id: "",
                owner: asHexAddress(k)
            })
        })

        console.log("nick entries", a);
        return a
    } catch (err) {
        console.log("error getting all name ids", err)
    }
}


export const GetAllOwnedNameIds = async (
    api: ApiPromise,
    account: string
    // todo
    // some sort of limit and offset
) => {
    try {
        let res = await api.query.nameService.owned.entries(account)
        let names: NameInfo[] = []
        res.forEach(([key, c_]) => {
            //let k = key.slice(key.length - 32, key.length)
            let k = key.toHuman() as string[]
            let owner = decodeAddress(k[0])
            //console.log("key",addr)
            let id = k[1]
            names = names.concat({
                id,
                owner: asHexAddress(owner)
            })
        })

        console.log("owned names entries", names);
        return names
    } catch (err) {
        console.log("error getting owned name ids", err)
    }
}


export const GetNameInfo = async (
    api: ApiPromise,
    id: string
) => {
    try {
        let res = await api.query.nameService.names(id)
        let data = res.unwrap()
        console.log("name info", data);
        return NameServiceNameInfoToNameInfo(data)
    } catch (err) {
        console.log("error getting owned name ids", err)
    }
}

export const NameServiceNameInfoToNameInfo = (name: NameServiceNameInfo) => {
    let data: NameInfo = {
        id: name.id.toHuman() as string,
        owner: asHexAddress(decodeAddress(name.owner)),
        name: name.name.toHuman() as string,
       www: name.www.toHuman() as string,
    }
    return data
}