//import "@polkadot/api-augment/substrate"
import '../interfaces/augment-types';
import '../interfaces/augment-api';
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {ApiPromise, WsProvider} from "@polkadot/api";
import {ScProvider} from "@polkadot/rpc-provider";
import {createScClient} from "@substrate/connect";
import * as Sc from "@substrate/connect";
import jsonCustomSpec from "../../ChainSpecRaw.json";
import {Header} from "@polkadot/types/interfaces/runtime/types";


export const devSeeds = [
    "curve engage scale soda anchor later wife unable click oxygen author hollow",
    "puppy slot blush utility upon describe receive mail tree anxiety pilot sport",
    "cattle brisk security interest kiss leader best wear else verb weekend jewel",
    "green there song bench cliff scheme uphold pear roof menu reveal element"
]

export interface BlockchainState {
    isConnected: boolean;
    api?: ApiPromise;
    lcApi?: ApiPromise;
    network?: string,
    lastHeader?: Header,
}

const DefaultState: BlockchainState = {
    isConnected: false,
};

const BlockchainContext = React.createContext<BlockchainState>(DefaultState);

const localDev = "ws://127.0.0.1:9944"
const remoteDev = "wss://testnet.superdiamondhands.com"
console.log("API URL FROM ENV", process.env.DIAMOND_REALM_API_URL)
const wsProvider = new WsProvider(remoteDev); //process.env.DIAMOND_REALM_API_URL
export const ADDR = "5FA9nQDVg267DEd8m1ZypXLBnvN7SFxYwV7ndqSYGiN9TTpu"
const BlockchainProvider = (props: { children: React.ReactNode }) => {
    const [blockchain, setBlockchain] = useState<BlockchainState>(DefaultState);

    const [balance, setBalance] = useState<string>();

    useEffect(() => {
        connectApi().then(() => {
            //connectScApi();
        })
    }, []);

    useEffect(() => {
        //  setupSubscriptions()
    }, [blockchain.isConnected])

    /// end of useEffects

    const setupSubscriptions = async () => {
        if (!blockchain.isConnected || !blockchain?.api) return

        // Retrieve the current timestamp via subscription
        //const unsubTimestamp = await blockchain.api.query.timestamp.now((moment) => {
        //console.log(`The last block has a timestamp of ${moment}`);
        //});

        // Subscribe to balance changes for our account
        /*  const unsub = await blockchain.api.query.system.account(ADDR, ({ nonce, data:balance }) => {
            console.log(`free balance is ${balance.free} with ${balance.reserved} reserved and a nonce of ${nonce}`);
            setBalance(balance.free.toHuman())
          });*/

        //subscribe to new headers
        let unsub = await blockchain.api.rpc.chain.subscribeNewHeads((lastHeader) => {
            //console.log(` last block #${lastHeader.number} has hash ${lastHeader.hash}`);
            setBlockchain({
                ...blockchain,
                lastHeader: lastHeader as unknown as Header,
            });
        });
        return unsub
        /*
        with extra data teh author via using the derive
        const unsub = await api.derive.chain.subscribeNewHeads((lastHeader) => {
        console.log(`#${lastHeader.number} was authored by ${lastHeader.author}`);
        });

        with unsub
        let count = 0;

        // Subscribe to the new headers
        const unsubHeads = await api.rpc.chain.subscribeNewHeads((lastHeader) => {
          console.log(`${chain}: last block #${lastHeader.number} has hash ${lastHeader.hash}`);

          if (++count === 10) {
            unsubHeads();
          }
        });
         */

    }

    const connectApi = async () => {
        let api = await ApiPromise.create({
            provider: wsProvider,
            rpc: {
                template: {
                    sum5: {
                        description: 'Just a test method',
                        params: [
                            {
                                name: 'value',
                                type: 'u32'
                            }/*,
              {
                name: 'at',
                type: 'Hash',
                isOptional: true
              }*/
                        ],
                        type: 'Balance'
                    },
                },
            },
        });
        console.log("Api Query System", api.query.system);
        //console.log("m", api.query.system.blockHash());
        // console.log("Api Consts", api.consts);
        // console.log("metadata", api.runtimeMetadata)

        const now = await api.query.timestamp.now();

        // Retrieve the account balance & nonce via the system module
        const resp = await api.query.system.account(ADDR);
        // console.log(`${now}: balance of ${resp} `);
        // Retrieve the chain name
        const chain = await api.rpc.system.chain();
        // Retrieve the latest header
        const lastHeader = await api.rpc.chain.getHeader();


        console.log(`Connected to ${chain}: last block #${lastHeader.number} has hash ${lastHeader.hash}`);

        setBlockchain({
            ...blockchain,
            isConnected: api.isConnected,
            api,
            network: chain.toString()
        });
    };

    //"/dns/ws.superdiamondhands.com/tcp/9946/ws/p2p/12D3KooWCVV9BBHkMuus7fGw7wXdA1WqDeKvt34oFFjSWjBAX6N1"
    //"/dns/ws.superdiamondhands.com/tcp/30334/ws/p2p/12D3KooWCVV9BBHkMuus7fGw7wXdA1WqDeKvt34oFFjSWjBAX6N1"
    //dns/2.westend.paritytech.net/tcp/30334/ws/p2p/12D3KooWByVpK92hMi9CzTjyFg9cPHDU5ariTM3EPMq9vdh5S5Po",
    const connectScApi = async () => {
        //todo needs fixing
        // todo fix its not able to query entries on our pallets
        //https://substrate.stackexchange.com/questions/6605/substrate-based-parachain-light-client-seems-to-gets-connected-but-apipromise-is
        console.log(`starting connect with sc`);

        // Create the provider for the custom chain
        const customSpec = JSON.stringify(jsonCustomSpec);
        //console.log(customSpec);

        // this is failing
        const provider = new ScProvider(Sc, customSpec);
        console.log("provider", provider)
        // Stablish the connection (and catch possible errors)
        await provider.connect();
        console.log("after connect", provider)
        const _api = await ApiPromise.create({provider});
        console.log(` SC Light Client API`, _api);
        /* await _api.rpc.chain.subscribeNewHeads((lastHeader) => {
           console.log("Light Client Last Header", lastHeader);
         });*/
        // Retrieve the chain name
        const chain = await _api.rpc.system.chain();
        console.log(` now connected to ${provider}`, _api);

        setBlockchain(prev => {
            return {
                ...prev,
                //isConnected: _api.isConnected,
                lcApi: _api,
                //network: chain.toString(),
            }
        });
    };

    //todo get wallets and accounts
    //todo do we put the extension stuff in here


    return (
        <BlockchainContext.Provider value={blockchain}>
            {props.children}
        </BlockchainContext.Provider>
    );
};

const useBlockchain = () => useContext(BlockchainContext);

export {useBlockchain, BlockchainContext, BlockchainProvider};
